import Vue from "vue";
import Vuex from "vuex";
import Axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products: [
      {
        id: 1,
        name: 'Okna',
        img: 'window.png',
        options: {
          material: [ 'Aluminium', 'PVC' ]
        },
        info: [
          {
            image: 'pvc-image.png',
            title: 'PVC',
            desc: 'Doskonałe walory techniczne, odporne na promienie UV, łatwe w pielęgnacji i atrakcyjne cenowo.'
          },
          {
            image: 'al-image.png',
            title: 'Aluminium',
            desc: 'Estetyczne akcenty w kształcie i kolorze, trwała i odporna na warunki atmosferyczne aluminiowa obudowa.'
          }
        ]
      },
      {
        id: 2,
        name: 'Okna HST',
        img: 'window2.png',
        options: {
          material: [ 'PVC' ]
        }
      },
      {
        id: 3,
        name: 'Drzwi zewnętrzne',
        img: 'window3.png',
        options: {
          material: [ 'Aluminium', 'PVC' ]
        }
      }
    ],
    purposes: [
      {
        id: 1,
        name: 'Nowe budownictwo',
        options: [
          'Dom prywatny', 'Mieszkanie', 'Biurowiec'
        ],
        img: 'purpose-1.svg'
      },
      {
        id: 2,
        name: 'Renowacja',
        options: [
          'Dom prywatny', 'Mieszkanie', 'Biurowiec'
        ],
        img: 'purpose-2.svg'
      }
    ],
    dates: [
      {
        name: '1-4 Miesiące',
        img: 'date-1.svg',
      },
      {
        name: '5-8 Miesięcy',
        img: 'date-2.svg',
      },
      {
        name: '>9 Miesięcy',
        img: 'date-3.svg',
      },
    ],
    selectedProducts: {},
    selectedPurpose: {},
    selectedDate: '',
    selectedLocalisation: '',
    distributor: {},
    personalData: {
      name: '',
      surname: '',
      company: '',
      phone: '',
      email: '',
      city: '',
      postcode: '',
      street: '',
      number: '',
      info: '',
      agree: false
    },
    steps: [
      {
        name: 'step1',
        completed: false
      },
      {
        name: 'step2',
        completed: false
      },
      {
        name: 'step3',
        completed: false
      },
      {
        name: 'step4',
        completed: false
      },
      {
        name: 'step5',
        completed: false
      },
      {
        name: 'step6',
        completed: false
      }
    ]
  },
  mutations: {
    saveProduct(state, payload) {
      if (payload.value) {
        let options = {};
        let allOptions = state.products.find(item => item.id == payload.id).options;

        Object.keys(allOptions).map(key => {
          Vue.set(options, key, allOptions[key][0]);
        });

        Vue.set(options, 'amount', 1);
        Vue.set(state.selectedProducts, payload.id, options);
      } else {
        Vue.delete(state.selectedProducts, payload.id);
      }
    },
    updateOption(state, payload) {
      Vue.set(state.selectedProducts[payload.id], 'material', payload.option);
    },
    updateValue(state, payload) {
      Vue.set(state.selectedProducts[payload.id], 'amount', payload.value);
    },
    updatePurpose(state, payload) {
      Vue.set(state, 'selectedPurpose', {});
      Vue.set(state.selectedPurpose, 'id', payload.id);

      if ('options' in state.purposes.find(item => item.id == payload.id)) {
        Vue.set(state.selectedPurpose, 'option', '');
        Vue.set(state.selectedPurpose, 'custom', false);
      }
    },
    updatePurposeOption(state, payload) {
      Vue.set(state.selectedPurpose, 'option', payload.option);
      Vue.set(state.selectedPurpose, 'custom', payload.custom);
    },
    updateDate(state, payload) {
      state.selectedDate = payload.date;
    },
    updateLocalisation(state, payload) {
      state.selectedLocalisation = payload.value;
    },
    updatePersonalData(state, payload) {
      Vue.set(state.personalData, payload.handle, payload.value);
    },
    updateDistributor(state, payload) {
      Vue.set(state, 'distributor', payload.value);
    },
    completeStep(state, payload) {
      let currentIndex = state.steps.findIndex(step => step.name == payload.name);

      // state.steps.forEach((step, index) => {
      //   if (index > currentIndex) {
      //     step.completed = false;
      //   }
      // });

      state.steps[currentIndex].completed = true;
    },
  },
  actions: {
    updateLocalisation({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        Axios({ url: `${process.env.VUE_APP_ENDPOINTS_URL}/api/distributor`, method: 'post', data: {
          lat: data.geometry.location.lat(),
          lng: data.geometry.location.lng()
        }, headers: { Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`, Accept: 'application/json' }})
        .then(resp => {
          commit('updateLocalisation', { value: data.formatted_address });
          commit('updateDistributor', { value: resp.data });
          resolve(resp);
        })
        .catch(err => {
           reject(err);
        });
        
     });
    },
    sendForm({ state }) {
      let order = {
        date: state.selectedDate,
        distributor_id: state.distributor.id,
        location: state.selectedLocalisation
      };

      let products = state.selectedProducts;
      Object.keys(products).forEach(key => products[key].name = state.products.find(item => item.id == key).name);

      let purpose = {
        category: state.purposes.find(item => item.id == state.selectedPurpose.id).name,
        option: state.selectedPurpose.option
      };

      let address = state.personalData;
      delete address.agree;

      return new Promise((resolve, reject) => {
         
        Axios({ url: `${process.env.VUE_APP_ENDPOINTS_URL}/api/orders`, method: 'post', data: {
          order,
          products,
          purpose,
          address
        }, headers: { Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`, Accept: 'application/json' }})
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
        
     });
    }
  },
  modules: {}
});
