<template>
  <header class="m-header">
    <div class="container">
      <div class="row">
        <div class="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8 col-xl-10 offset-xl-1">
          <div class="m-header__wrap">
            <div class="m-header__left">
              <a href="https://www.dobroplast.pl" rel="nofollow" class="m-header__logo">
                <img src="@/assets/images/logo.svg" alt="Dobroplast">
              </a>
              <!-- / m-header__logo -->
              
              <a href="https://sklep.dobroplast.pl" rel="nofollow" class="m-header__link">Przejdź do sklepu Dobroplast</a>
            </div>

            <ul class="m-header__nav" v-if="!isHomePage">
              <li v-for="(step, index) in steps" :key="index">
                <a @click="changeStep(step)" :class="{ 'active': step.completed }">{{ String(index+1).padStart(2, '0') }}</a>
              </li>
            </ul>
            <!-- / m-header__nav -->

          </div>
          <!-- / m-header__wrap -->

        </div>
      </div>
    </div>
  </header>
  <!-- / m-header -->

</template>

<script>
export default {
  name: "Nav",
  props: {
    isHomePage: Boolean,
  },
  methods: {
    changeStep(step) {
      if (step.completed) {
        this.$router.push({ path: step.name }).catch(() => {});
      }
    }
  },
  computed: {
    steps() {
      return this.$store.state.steps;
    },
  },
};
</script>

<style scoped lang="scss">
  .m-header {
    padding: 25px 0 40px 0;
    position: relative;

    @media #{$md} {
      padding: 47px 0 40px 0;
    }

    &:before {
      content: "";
      width: 100%;
      height: 174px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      background-color: $color-bg-alt;
    }

    &__wrap {
      @include flex(space-between, flex-end, wrap);
      
      @media #{$lg} {
        flex-wrap: wrap;
      }
    }
    
    &__left {
      @include flex(flex-start, flex-end, wrap);
      margin-bottom: 20px;
      
      @media #{$md} {
        margin-bottom: 0;
      }
    }

    &__logo {
      margin-right: 30px;
      display: block;
      font-size: 0;
      

      img, svg {
        width: 113px;
        height: auto;
      }
    }
    
    &__link {
      text-decoration: underline;
      font-family: $font-main;
      color: $color-primary;
      margin-bottom: 1px;
      font-weight: 600;
      font-size: 14px;
      
      @media #{$xs} {
        margin-top: 20px;
      }
      
      &:hover {
        text-decoration: none;
      }
    }

    &__nav {
      @include flex(space-between, flex-end, nowrap);
      margin-top: 15px;
      width: 100%;
      
      @media #{$md} {
        margin-top: 25px;
      }

      @media #{$xl} {
        justify-content: flex-end;
        width: auto;
      }

      & > li {
        @media #{$md} {
          padding-left: 45px;
        }
        @media #{$xl} {
          padding-left: 56px;
        }
        
        &:first-of-type {
          
          @media #{$md} {
            padding-left: 0;
          }
        }

        & > a {
          @extend %nav-item;
          color: $color-text-light;

          &.active {
            color: $color-primary;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
