import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'vue-progress-path/dist/vue-progress-path.css'
import VueProgress from 'vue-progress-path'
import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin'

Vue.config.productionTip = false;

// aos
import AOS from 'aos'
import 'aos/dist/aos.css'

new Vue({
  router,
  store,
  created() {
    AOS.init({
      once: true,
      duration: 1000
    });
  },
  render: h => h(App)
}).$mount("#app");

Vue.mixin({
  methods: {
    checkRoute(to, from) {
      let toNumber = to.name.split('p')[1];
      let fromNumber = from.name.split('p')[1];

      return fromNumber > toNumber;
    },
  },
});

Vue.use(VueProgress, {
  defaultShape: "circle"
});

Vue.use(VueCookieBot, {
  cookieBotID: '41ec2d07-cf2e-4e60-a814-76a8741f0024'
})

// iOS input zoom fix
const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector("meta[name=viewport]");
    if (el !== null) {
        let content = el.getAttribute("content");
        let re = /maximum\-scale=[0-9\.]+/g;
        if (re.test(content)) {
            content = content.replace(re, "maximum-scale=1.0");
        } else {
            content = [content, "maximum-scale=1.0"].join(", ");
        }
        el.setAttribute("content", content);
    }
};
const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;
const checkIsIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
if (checkIsIOS()) {
    disableIosTextFieldZoom();
}
