<template>
  <div id="app">
    <Nav :isHomePage="isHomePage" />
    <main :class="['m-app', hasWhiteBg ? 'm-app--whiteBg' : '']">
      <div class="container">
        <div class="row">
          <div class="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8 col-xl-10 offset-xl-1">
            <div class="m-app__inner">
              <router-view />
            </div>
            <!-- / m-app__inner -->
            
          </div>
        </div>
      </div>
    </main>
    <!-- / m-app -->

    <Footer />

  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      hasWhiteBg: false,
      isHomePage: false,
    }
  },
  mounted() {
    if (this.$route.name != 'home') {
      this.$router.push({ path: '/' }).catch(() => {});
    }
  },
  computed: {
    steps() {
      return this.$store.state.steps;
    },
  },
  created() {
    this.isHomePage = (this.$router.currentRoute.name == 'home') ? true : false;
  },
  watch:{
    $route (to, from) {
      this.hasWhiteBg = (to.name == 'step1' || to.name == 'step2' || to.name == 'step3' || to.name == 'step4') ? true : false;
      this.isHomePage = (to.name == 'home') ? true : false;
      
      if (to.name != 'summary' && to.name != 'home') {
        this.$store.commit('completeStep', { name: to.name });
      }

      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>

<style lang="scss">
  body {
    background-color: $color-bg-alt;
    display: block;
  }
  #app {
    overflow: hidden;
  }
  .m-app {
    padding-bottom: 40px;
    position: relative;

    @media #{$md} {
      padding-bottom: 60px;
      min-height: 700px;
    }
    @media #{$xl} {
      padding-bottom: 80px;
    }
    
    .step {
      border: 10px solid $color-primary;
    }
    
    &--whiteBg {
      
      &:before {
        content: '';
        background-color: $color-bg;
        height: calc(100% - 130px);
        position: absolute;
        width: 100%;
        top: 130px;
        left: 0;
      }
    }
  }
</style>
