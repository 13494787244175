import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "Step1" */ "../views/Home.vue")
  },
  {
    path: "/step1",
    name: "step1",
    component: () => import(/* webpackChunkName: "Step1" */ "../views/Step1.vue")
  },
  {
    path: "/step2",
    name: "step2",
    component: () => import(/* webpackChunkName: "Step2" */ "../views/Step2.vue")
  },
  {
    path: "/step3",
    name: "step3",
    component: () => import(/* webpackChunkName: "Step3" */ "../views/Step3.vue")
  },
  {
    path: "/step4",
    name: "step4",
    component: () => import(/* webpackChunkName: "Step4" */ "../views/Step4.vue")
  },
  {
    path: "/step5",
    name: "step5",
    component: () => import(/* webpackChunkName: "Step5" */ "../views/Step5.vue")
  },
  {
    path: "/step6",
    name: "step6",
    component: () => import(/* webpackChunkName: "Step6" */ "../views/Step6.vue")
  },
  {
    path: "/summary",
    name: "summary",
    component: () => import(/* webpackChunkName: "Summary" */ "../views/Summary.vue")  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  next();
});

export default router;
