<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8 col-xl-10 offset-xl-1">
          <div class="footer__container">
            <div class="footer__infos">
              <div class="footer-box">
                <p class="footer-box__header">Adres</p>
                <div class="footer-box__content">
                  <p>Dobroplast</p>
                  <p>Fabryka Okien Sp. z o.o.</p>
                  <p>Stary Laskowiec 4</p>
                  <p>18-300 Zambrów</p>
							  </div>
              </div>
              <div class="footer-box">
                <p class="footer-box__header">Kontakt</p>
                <div class="footer-box__content">
                  <p>tel.: +48 86 276 35 00</p>
                  <p>fax: +48 85 674 32 55</p>
                  <p><a href="mailto:dobroplast@dobroplast.pl">dobroplast@dobroplast.pl</a></p>
							  </div>
              </div>
            </div>
            <div class="footer__links">
              <div class="footer-box">
                <p class="footer-box__header">Social</p>
                <div class="footer-box__content">
                  <ul class="footer-box__socials">
                    <li><a href="https://www.facebook.com/DobroplastFabryka/" rel="nofollow" target="_blank">Facebook</a></li>
                    <li><a href="https://www.youtube.com/channel/UCz5gzWMqtokMmKZ4QCdusAw" rel="nofollow" target="_blank">YouTube</a></li>
                  </ul>
                </div>
              </div>
              <a href="#" class="footer__scroll" @click.prevent="scrollToTop()">
                <img src="@/assets/images/arrow-up.svg" class="footer__arrow" alt="svg">
                <p>Powrót</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bar">
      <div class="container">
        <div class="row">
          <div class="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <div class="footer__flex">
              <div class="footer__company">
                <p>Spółka należąca do grupy DOVISTA</p>
                <img src="@/assets/images/dovista.png" alt="Dovista">
              </div>
              <p class="footer__policy"><a href="https://dobroplast.pl/wp-content/uploads/2021/10/Polityka_prywatnos%CC%81ci_2021.pdf" rel="nofollow" target="_blank">Polityka prywatności</a></p>
              <p class="footer__copyrights">{{ new Date().getFullYear() }} © Dobroplast Fabryka Okien sp. z o.o.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- / footer -->

</template>

<script>
// @ is an alias to /src

export default {
  name: "Footer",
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style scoped lang="scss">
.footer {
  background-color: $color-bg-alt;
  position: relative;
  z-index: 1;

  &__container {
    color: $color-text;
    padding: 30px 0;

    @media #{$md} {
      @include flex(space-between, flex-start, nowrap);
      padding: 55px 0;
    }
  }

  &__infos {
    @include flex(flex-start, flex-start, nowrap);

    .footer-box {
      &:nth-child(n+2) {
        padding-left: 20px;
        @media #{$md} {
          padding-left: 48px;
        }
      }
    }
  }

  &__links {
    margin-top: 20px;
    @include flex(space-between, flex-start, nowrap);

    @media #{$md} {
      justify-content: flex-end;
      margin-top: 0;
    }

    .footer-box {
      @media #{$md} {
        padding-right: 60px;
      }
      @media #{$xl} {
        padding-right: 148px;
      }
    }
  }

  &__scroll {
    @extend %info;
    display: block;
    text-align: center;

    @media (max-width: $max-sm) {
      align-self: flex-end;
    }

    p {
      @include transition();
      font-weight: 600;
      color: $color-primary;
      line-height: 2.15;
    }

    &:hover {

      p {
        color: $color-text;
      }
    }
  }

  &__arrow {
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    height: 20px;
    width: 25px;

    img, svg {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  &__bar {
    @extend %product-name;
    background-color: $color-footer;
    color: $color-text-light;
    line-height: 1.85;
    font-size: 12px;
    padding: 26px 0;
  }

  &__flex {
    @media #{$md} {
      @include flex(space-between, center, nowrap);
    }
  }

  &__company {
      img, svg {
      margin-top: 4px;
      height: 30px;
      width: auto;
    }
  }

  &__policy {

    a {
      @include transition();

      &:hover {
        text-decoration: underline;
        color: $color-primary;
      }
    }
  }
}

.footer-box {
  @extend %info;
  font-size: 13px;

  @media (min-width: 360px) and (max-width: $max-sm) {
    flex: 0 0 50%;
  }

  &__header {
    color: $color-primary;
    margin-bottom: 5px;

    @media #{$md} {
      margin-bottom: 20px;
    }
  }

  &__content {
    line-height: 2.31;

    a {
      color: $color-primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__socials {
    a {
      @include transition();
      color: $color-text;

      &:hover {
        color: $color-primary;
        text-decoration: underline;
      }
    }
  }
}
</style>
